import Breadcrumb from "../../components/UI/Breadcrumb";
import TableOne from "../../components/UI/TableOne";
import TableThree from "../../components/UI/TableThree";
import TableTwo from "../../components/UI/TableTwo";
import UserTable from "../../components/UI/UserTable";

const Tables = () => {
  return (
    <>
      {/* <TableOne/>
      <TableTwo/> */}
      {/* <TableThree/> */}
      <UserTable/>
    </>
  );
};

export default Tables;
